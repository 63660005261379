
import { computed, defineComponent, toRefs } from "vue";

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false
    },
    listData: {
      type: Array,
      default: []
    }
  },
  emits: ["update:show", "confirm"],
  setup(props, { emit }) {
    const { show } = toRefs(props);
    const popupShow = computed({
      get() {
        return show.value;
      },
      set(value) {
        emit("update:show", value);
      }
    });
    const handleClose = () => {
      popupShow.value = false;
    };
    return {
      popupShow,
      handleClose
    };
  }
});
