
import { visitorService } from "@/service";
import { reactive, ref } from "vue";
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Toast } from "vant";
import approver from "./components/approver.vue";
import { RouterName } from "@/router";

export default defineComponent({
  components: {
    approver
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const detail = reactive<any>({});

    const getDetail = async () => {
      try {
        const { data } = await visitorService.visitorDetail(route.query.id as string);
        Object.assign(detail, data.data);
      } catch (error) {}
    };
    getDetail();
    const handleClick = (type: string) => {
      router.push({
        name: RouterName.VISITOR_RESULT,
        query: {
          type: type,
          visitor_token: detail.visitor_token,
          id: detail.id
        }
      });
    };

    const show = ref(false);
    return {
      show,
      detail,
      handleClick
    };
  }
});
